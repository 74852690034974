import { z } from 'zod';
import { axiosAuth } from '@libs/axios';
import { ProductVariantSchema } from '@api/schema';

const getPersonaTemplatesSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    imageURL: z.string().url(),
  })
);

const getPersonaTemplateSchema = z.object({
  id: z.number(),
  name: z.string(),
  imageURL: z.string().url(),
  filters: z.object({
    productCategories: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    ),
    userCategories: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    ),
    targetAudience: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    ),
    values: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        unicode: z.string(),
        imageURL: z.string().url(),
      })
    ),
    priceSegment: z.union([
      z.null(),
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    ]),
    topography: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    ),
    size: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    ),
    spot: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    ),
  }),
});

export const getPersonasSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    image: z.string().url().nullable(),
    isPublished: z.boolean(),
    _count: z.object({
      retailers: z.number(),
    }),
  })
);

export const getRetailersForPersonaSchema = z.object({
  result: z.object({
    totalCount: z.number(),
    retailers: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        productCategories: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        userCategories: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        targetAudience: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        values: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
            unicode: z.string().optional(),
            imageURL: z.string().url(),
          })
        ),
        monthlyWHSSpend: z.union([
          z.null().optional(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        priceSegment: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        size: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        spot: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        topography: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
      })
    ),
  }),
});

export const getRetailersForBrandSchema = z.object({
  result: z.object({
    totalCount: z.number(),
    retailers: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        productCategories: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        userCategories: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        targetAudience: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
          })
        ),
        values: z.array(
          z.object({
            id: z.number(),
            name: z.string(),
            unicode: z.string().optional(),
            imageURL: z.string().url(),
          })
        ),
        monthlyWHSSpend: z.union([
          z.null().optional(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        priceSegment: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        size: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        spot: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
        topography: z.union([
          z.null(),
          z.object({
            id: z.number(),
            name: z.string(),
          }),
        ]),
      })
    ),
  }),
});

export const getProductsForPersonaSchema = z.array(ProductVariantSchema);

const getPersonaSchema = z.object({
  id: z.number(),
  name: z.string(),
  isPublished: z.boolean(),
  isDeleted: z.boolean(),
  moq: z.number(),
  image: z.string().url().nullable(),
  discount: z.number(),
  additionalDiscount: z.number(),
  brandId: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  PriceList: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional()
    .nullable(),
});

const getAllRetailerAttributesSchema = z.object({
  productCategories: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  userCategories: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  targetAudience: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  priceSegment: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  values: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
        unicode: z.string(),
      })
    )
    .optional(),
  topography: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  size: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  spot: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
  monthlyWHSSpend: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
});

const createAndDeletePersonaResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  isPublished: z.boolean(),
  isDeleted: z.boolean(),
  moq: z.number(),
  image: z.string().nullable(),
  additionalDiscount: z.number(),
  brandId: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const persona = {
  getPersonaTemplates: {
    queryKey: 'getPersonaTemplatesSchema',
    schema: getPersonaTemplatesSchema,
    query: async () => {
      const response = await axiosAuth.get('/persona/get-persona-templates');
      return getPersonaTemplatesSchema.parse(response.data);
    },
  },
  getPersonaTemplate: {
    queryKey: 'getPersonaTemplateSchema',
    schema: getPersonaTemplateSchema,
    query: async templateId => {
      const response = await axiosAuth.get(
        `/persona/get-persona-template?templateId=${templateId}`
      );
      return getPersonaTemplateSchema.parse(response.data);
    },
  },
  getPersonas: {
    queryKey: 'getPersonas',
    schema: getPersonasSchema,
    query: async (brandId: number, skip?: number, take?: number, searchTerm?: string) => {
      const response = await axiosAuth.get(`/persona/get-personas`, {
        params: { brandId, skip, take, searchTerm },
      });
      return getPersonasSchema.parse(response.data);
    },
  },
  createPersona: {
    schema: z.string(),
    mutation: async ({
      brandId,
      name,
      moq,
      discount,
      additionalDiscount,
      image,
      priceListId,
    }: {
      brandId: number;
      name: string;
      moq: number;
      discount?: number;
      additionalDiscount?: number | undefined;
      priceListId?: number;
      image?: string;
    }) => {
      const response = await axiosAuth.post('/persona/create-persona', {
        brandId,
        name,
        moq,
        discount,
        additionalDiscount,
        priceListId,
        ...(image && { image }),
      });
      return createAndDeletePersonaResponseSchema.parse(response.data);
    },
  },
  updatePersona: {
    mutation: async (formData: FormData) => {
      const response = await axiosAuth.post('/persona/update-persona', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
  },
  deletePersona: {
    schema: z.string(),
    mutation: async (personaId: number) => {
      const response = await axiosAuth.post('/persona/delete-persona', {
        personaId,
      });
      return createAndDeletePersonaResponseSchema.parse(response.data);
    },
  },
  getAllRetailerAttributes: {
    queryKey: 'getAllRetailerAttributes',
    schema: getAllRetailerAttributesSchema,
    query: async () => {
      const response = await axiosAuth.get('/management/retailer/get-all-retailer-attributes');
      return getAllRetailerAttributesSchema.parse(response.data);
    },
  },
  getPersona: {
    queryKey: 'getPersona',
    schema: getPersonaSchema,
    query: async (personaId: number) => {
      const response = await axiosAuth.get(`/persona/get-persona?personaId=${personaId}`);
      return getPersonaSchema.parse(response.data.persona);
    },
  },
  getRetailersForPersona: {
    queryKey: 'getRetailersForPersona',
    schema: getRetailersForPersonaSchema,
    query: async ({
      personaId,
      searchTerm,
      skip,
      take,
    }: {
      personaId: number;
      searchTerm?: string;
      skip: number;
      take: number;
    }) => {
      const response = await axiosAuth.get(
        `/persona/get-persona-retailers?personaId=${personaId}&skip=${skip}&take=${take}&searchTerm=${searchTerm}`
      );
      return getRetailersForPersonaSchema.parse(response.data);
    },
  },
  getRetailersForBrand: {
    queryKey: 'getRetailersForBrand',
    schema: getRetailersForBrandSchema,
    query: async (data: {
      brandId: number;
      searchTerm?: string;
      filters?: {
        productCategories: string[];
        userCategories: string[];
        targetAudience: string[];
        values: string[];
        topography: string[];
        size: string[];
        spot: string[];
        priceSegment: string;
      };
      take: number;
      skip: number;
    }) => {
      const { brandId, take, skip, filters, searchTerm } = data;
      // TODO: Check which filters are arrays
      //TODO: Need to add searchTerm when api is accpecting search value
      const response = await axiosAuth.get(
        `/persona/get-retailers?brandId=${brandId}&take=${take}&skip=${skip}&searchTerm=${searchTerm}`,
        {
          params: {
            productCategories:
              filters?.productCategories.length > 0 ? filters?.productCategories.join(',') : null,
            userCategories:
              filters?.userCategories.length > 0 ? filters?.userCategories.join(',') : null,
            targetAudience:
              filters?.targetAudience.length > 0 ? filters?.targetAudience.join(',') : null,
            values: filters?.values.length > 0 ? filters?.values.join(',') : null,
            topography: filters?.topography.length > 0 ? filters?.topography.join(',') : null,
            size: filters?.size.length > 0 ? filters?.size.join(',') : null,
            spot: filters?.spot.length > 0 ? filters?.spot.join(',') : null,
            priceSegmentId: filters?.priceSegment || null,
          },
        }
      );

      return getRetailersForBrandSchema.parse(response.data);
    },
  },
  updateRetailersForPersona: {
    schema: z.string(),
    mutation: async ({ personaId, retailerIds }: { personaId: number; retailerIds: number[] }) => {
      await axiosAuth.post('/persona/add-retailers', {
        personaId,
        retailerIds,
      });
    },
  },
  getProductsForPersona: {
    queryKey: 'getProductsForPersona',
    schema: getProductsForPersonaSchema,
    query: async (personaId: number) => {
      const response = await axiosAuth.get(`/persona/get-persona-products?personaId=${personaId}`);
      return getProductsForPersonaSchema.parse(response.data.result.products);
    },
  },
  updateProductsForPersona: {
    schema: z.string(),
    mutation: async ({ personaId, productIds }: { personaId: number; productIds: string[] }) => {
      await axiosAuth.post('/persona/update-variants', {
        personaId,
        skus: productIds,
      });
    },
  },
  addProductsToPersona: {
    schema: z.string(),
    mutation: async ({ personaId, skus }: { personaId: number; skus: string[] }) => {
      await axiosAuth.post('/persona/add-variants', {
        personaId,
        skus,
      });
    },
  },
  deletePersonaRetailer: {
    mutation: async (requestData: { retailerIds: number[]; personaId: number }) => {
      const response = await axiosAuth.post('/persona/remove-retailers', {
        ...requestData,
      });
      return response.data;
    },
  },
};

export default persona;
