import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const navItemSchema = z.object({
  name: z.string(),
  path: z.string(),
  children: z
    .array(
      z.object({
        name: z.string(),
        path: z.string(),
        children: z
          .array(
            z.object({
              name: z.string(),
              path: z.string(),
              children: z
                .array(
                  z.object({
                    name: z.string(),
                    path: z.string(),
                  })
                )
                .optional(),
            })
          )
          .optional(),
      })
    )
    .optional(),
});

export const getHeroNavigationSchema = z.array(navItemSchema);

const brandFiltersSchema = z.object({
  brands: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional()
    .nullable(),
  values: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional()
    .nullable(),
  priceSegments: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional()
    .nullable(),
  shipsFrom: z
    .array(
      z.object({
        shipsFrom: z.string().nullable(),
      })
    )
    .optional()
    .nullable(),
  targetAudiences: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional()
    .nullable(),
  productCategories: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional()
    .nullable(),
  userCategories: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional()
    .nullable(),
  leadTime: z
    .object({
      min: z.number().nullable(),
      max: z.number().nullable(),
    })
    .optional(),
  moq: z
    .object({
      min: z.number().nullable(),
      max: z.number().nullable(),
    })
    .optional(),
  discount: z
    .object({
      min: z.number().nullable(),
      max: z.number().nullable(),
    })
    .optional(),
});

const getBrowsePageFiltersSchema = z.object({
  breadCrumb: z
    .object({
      heroCategory: z
        .object({
          name: z.string().optional(),
          path: z.string().optional(),
        })
        .optional(),
      subCategory1: z
        .object({
          name: z.string().optional(),
          path: z.string().optional(),
        })
        .optional(),
      subCategory2: z
        .object({
          name: z.string().optional(),
          path: z.string().optional(),
        })
        .optional(),
      productType: z
        .object({
          name: z.string().optional(),
          path: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
  childFilters: z.array(navItemSchema).optional().nullable(),
  brandFilters: brandFiltersSchema.optional(),
});

const getAllBrandsPageFiltersSchema = brandFiltersSchema.extend({
  values: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      })
    )
    .optional(),
});

const getAllBrandsSchema = z.object({
  totalCount: z.number(),
  brands: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      logoURL: z.string().url().nullable(),
      moq: z.nullable(z.number()),
      discount: z.nullable(z.number()),
      basedIn: z.nullable(z.string()),
      madeToOrder: z.nullable(z.boolean()),
      company: z.object({
        id: z.number(),
        name: z.string(),
      }),
      shipsTo: z
        .array(
          z.object({
            id: z.number(),
            name: z.string().nullable(),
          })
        )
        .nullable(),
      productCategories: z
        .array(
          z.object({
            id: z.number(),
            name: z.string().nullable(),
          })
        )
        .nullable(),
      userCategories: z
        .array(
          z.object({
            id: z.number(),
            name: z.string().nullable(),
          })
        )
        .nullable(),
      priceSegment: z
        .object({
          id: z.number(),
          name: z.string().nullable(),
        })
        .nullable(),
      targetAudience: z
        .array(
          z.object({
            id: z.number(),
            name: z.string().nullable(),
          })
        )
        .nullable(),
      values: z
        .array(
          z.object({
            id: z.number(),
            imageURL: z.string(),
            name: z.string(),
            unicode: z.string(),
          })
        )
        .optional(),
      brandOneLineStory: z.string().nullable(),
      slug: z.string(),
    })
  ),
});

const baseNavigationTreeSchema = z.object({
  name: z.string(),
  targetAudiencePaths: z.array(z.string()),
  userCategoryPaths: z.array(z.unknown()),
  productCategoryPaths: z.array(z.unknown()),
});

export type NavigationTreeType = z.infer<typeof baseNavigationTreeSchema> & {
  children: NavigationTreeType[];
};

const navigationTreeSchema: z.ZodType<NavigationTreeType> = baseNavigationTreeSchema.extend({
  children: z.lazy(() => navigationTreeSchema.array()),
});

export const getNavigationTreeSchema = z.object({
  navigationTree: navigationTreeSchema.array(),
});

export const browse = {
  getHeroNavigation: {
    queryKey: 'getHeroNavigation',
    schema: getHeroNavigationSchema,
    query: async () => {
      const response = await axiosAuth.get('/browse/get-hero-navigation');
      return getHeroNavigationSchema.parse(response.data?.result?.heroNavigation);
    },
  },
  getBrowsePageFilters: {
    queryKey: 'getBrowsePageFilters' as const,
    schema: getBrowsePageFiltersSchema,
    query: async ({ topicPath, brandId }: { topicPath: string; brandId?: number }) => {
      const response = await axiosAuth.get('/browse/get-browse-page', {
        params: {
          topicPath: topicPath,
          ...(brandId && { brandId }),
        },
      });
      return getBrowsePageFiltersSchema.parse(response.data?.result);
    },
  },
  getAllBrandsPageFilters: {
    queryKey: 'getAllBrandsPageFilters',
    schema: getAllBrandsPageFiltersSchema,
    query: async () => {
      const response = await axiosAuth.get('/browse/get-brand-filters');
      return getAllBrandsPageFiltersSchema.parse(response.data?.result?.filters);
    },
  },
  getBrands: {
    queryKey: 'getBrands',
    schema: getAllBrandsSchema,
    query: async ({
      retailerId,
      searchTerm,
      sortByField,
      sortByDirection,
      moqMin,
      moqMax,
      discountMin,
      discountMax,
      leadTimeMin,
      leadTimeMax,
      valueIds = [],
      targetAudienceIds = [],
      productCategoryIds = [],
      priceSegmentIds = [],
      shipsFrom = [],
      take,
      skip,
    }: {
      retailerId?: number;
      searchTerm?: string;
      sortByField?: string;
      sortByDirection?: string;
      productCategoryIds?: string[];
      valueIds?: string[];
      priceSegmentIds?: string[];
      targetAudienceIds?: string[];
      shipsFrom?: string[];
      moqMin?: string | number;
      moqMax?: string | number | undefined;
      discountMin?: string | number;
      discountMax?: string | number;
      leadTimeMin?: string | number;
      leadTimeMax?: string | number;
      take?: string | number;
      skip?: string | number;
    }) => {
      const response = await axiosAuth.get('/browse/get-brands', {
        params: {
          ...(retailerId !== undefined && { retailerId }),
          ...(searchTerm && { searchTerm }),
          ...(moqMin !== undefined && { moqMin }),
          ...(moqMax !== undefined && { moqMax }),
          ...(discountMin !== undefined && { discountMin }),
          ...(discountMax !== undefined && { discountMax }),
          ...(leadTimeMin !== undefined && { leadTimeMin }),
          ...(leadTimeMax !== undefined && { leadTimeMax }),
          ...(valueIds?.length > 0 && { valueIds: valueIds.join(',') }),
          ...(priceSegmentIds?.length > 0 && {
            priceSegmentIds: priceSegmentIds.join(','),
          }),
          ...(targetAudienceIds?.length > 0 && {
            targetAudienceIds: targetAudienceIds.join(','),
          }),
          ...(productCategoryIds?.length > 0 && {
            productCategoryIds: productCategoryIds.join(','),
          }),
          ...(shipsFrom?.length > 0 && { shipsFrom: shipsFrom.join(',') }),
          ...(sortByField && { sortByField }),
          ...(sortByDirection && { sortByDirection }),
          ...(take && { take }),
          ...(skip && { skip }),
        },
      });
      return getAllBrandsSchema.parse(response.data?.result);
    },
  },
  getNavigationTree: {
    queryKey: 'getNavigationTree',
    schema: getNavigationTreeSchema,
    query: async (brandId?: number) => {
      const response = await axiosAuth.get('/browse/navigation-tree', { params: { brandId } });
      return getNavigationTreeSchema.parse(response.data?.result);
    },
  },
};
