import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const getSellerOrderStatsSchema = z.object({
  stats: z.object({
    actionsRequired: z.number(),
    receivedOrdersCount: z.number(),
    receivedOrdersAmount: z.number(),
    processingOrdersCount: z.number(),
    processingOrdersAmount: z.number(),
    completedOrdersCount: z.number(),
    completedOrdersAmount: z.number(),
    scheduledOrdersCount: z.number(),
    scheduledOrdersAmount: z.number(),
  }),
});

const getSellerOrdersSchema = z.object({
  brandOrderCount: z.number(),
  brandOrders: z.array(
    z.object({
      id: z.number(),
      orderId: z.number(),
      displayId: z.string(),
      brandName: z.string(),
      retailerName: z.string(),
      requestedFor: z.string(),
      placedOn: z.string(),
      shipmentCarrier: z.string().nullable(),
      shipDate: z.string().nullable(),
      deliveryDate: z.string().nullable(),
      notes: z.string().nullable(),
      hasBuyerInvoice: z.boolean(),
      hasSellerBill: z.boolean(),
      hasSellerPurchaseOrder: z.boolean(),
      subtotal: z.number(),
      gstPercentage: z.number(),
      gstAmount: z.number(),
      commissionRate: z.number(),
      commissionAmount: z.number(),
      total: z.number(),
      status: z.object({
        id: z.number(),
        name: z.string(),
        displayName: z.string(),
      }),
      deliveryStatus: z
        .object({
          id: z.number(),
          name: z.string(),
          displayName: z.string(),
        })
        .nullable(),
      sellerPaymentStatus: z.object({
        id: z.number(),
        name: z.string(),
        displayName: z.string(),
      }),
      orderSource: z.enum(['Seller', 'Cart', 'Order Form']),
    })
  ),
});

const addressSchema = z.object({
  line1: z.string(),
  line2: z.string().nullable(),
  city: z.string(),
  state: z.string(),
  country: z.string(),
  postCode: z.string(),
});

export const getSellerOrderSchema = z.object({
  id: z.number(),
  displayId: z.string(),
  brandName: z.string(),
  requestedFor: z.string(),
  placedOn: z.string(),
  shipmentCarrier: z.string().nullable(),
  shipDate: z.string().nullable(),
  deliveryDate: z.string().nullable(),
  deliveryTrackingId: z.string().nullable(),
  subtotal: z.number(),
  gstPercentage: z.number(),
  gstAmount: z.number(),
  commissionRate: z.number(),
  commissionAmount: z.number(),
  total: z.number(),
  status: z.object({
    id: z.number(),
    name: z.string(),
    displayName: z.string(),
  }),
  deliveryStatus: z
    .object({
      id: z.number(),
      name: z.string(),
      displayName: z.string(),
    })
    .nullable(),
  sellerPaymentStatus: z.object({
    id: z.number(),
    name: z.string(),
    displayName: z.string(),
  }),
  buyerPaymentStatus: z.object({
    id: z.number(),
    name: z.string(),
    displayName: z.string(),
  }),
  hasBuyerInvoice: z.boolean(),
  hasSellerBill: z.boolean(),
  hasSellerPurchaseOrder: z.boolean(),
  retailerName: z.string(),
  retailerCompanyName: z.string(),
  retailerEmail: z.string().nullable().optional(),
  retailerStore: z
    .object({
      id: z.number(),
      name: z.string(),
      contactName: z.string().nullable(),
      contactNumber: z.string().nullable(),
      remarks: z.string().nullable(),
      address: addressSchema,
    })
    .optional(),
  cart: z.array(
    z.object({
      itemId: z.string(),
      name: z.string(),
      primaryVariantAttribute: z.string().nullable(),
      secondaryVariantAttribute: z.string().optional(),
      primaryVariants: z.array(
        z.object({
          attribute: z.string().nullable(),
          images: z
            .array(
              z.object({
                url: z.string().url(),
              })
            )
            .nullable(),
          secondaryVariants: z.array(
            z.object({
              attribute: z.string(),
              sku: z.string(),
              vendorSku: z.string(),
              subTotal: z.number(),
              price: z.number(),
              salePrice: z.number().nullable(),
              qty: z.number(),
              proposedQty: z.number().nullable(),
              newQty: z.number().nullable(),
              appliedCollectionId: z.number().nullable(),
              appliedCollectionDiscount: z.number().nullable(),
              appliedPersonaId: z.number().nullable(),
              appliedDiscount: z.number().nullable(),
              appliedAdditionalDiscount: z.number().nullable(),
              appliedBrandSystemPersonaId: z.number().nullable(),
            })
          ),
        })
      ),
    })
  ),
  notes: z.string().nullable().optional(),
});

const getShippingCarriersSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    requiresTrackingId: z.boolean(),
  })
);

//TODO: we need to add suitable zod schema for getOrderInvoiceSchema with response type: blob
const getOrderInvoiceSchema = z.any();

//TODO: we need to add suitable zod schema for getOrderPurchaseInvoiceSchema with response type: blob
const getOrderPurchaseInvoiceSchema = z.any();

export const order = {
  getSellerOrderStats: {
    queryKey: 'getSellerOrderStats',
    schema: getSellerOrderStatsSchema,
    query: async (brandId: number) => {
      const response = await axiosAuth.get(`/order/seller/get-order-stats?brandId=${brandId}`);
      return getSellerOrderStatsSchema.parse(response.data);
    },
  },
  getSellerOrders: {
    queryKey: 'getSellerOrders',
    schema: getSellerOrdersSchema,
    query: async ({
      brandId,
      type,
      skip,
      take,
      searchTerm,
      retailerId,
    }: {
      brandId: number;
      type: string | null;
      skip: number;
      take: number;
      searchTerm: string;
      retailerId: number | null;
    }) => {
      if (!retailerId) {
        const response = await axiosAuth.get(
          `/order/seller/get-orders?brandId=${brandId}&take=${take}&skip=${skip}`,
          {
            params: {
              type,
              ...(searchTerm && { searchTerm }),
            },
          }
        );
        return getSellerOrdersSchema.parse(response.data);
      } else {
        const response = await axiosAuth.get(
          `/order/seller/get-orders?brandId=${brandId}&retailerId=${retailerId}&take=${take}&skip=${skip}`,
          {
            params: {
              type,
              ...(searchTerm && { searchTerm }),
            },
          }
        );

        return getSellerOrdersSchema.parse(response.data);
      }
    },
  },
  getSellerOrder: {
    queryKey: 'getSellerOrder',
    schema: getSellerOrderSchema,
    query: async (orderId: number) => {
      const response = await axiosAuth.get(`/order/seller/get-order?brandOrderId=${orderId}`);
      return getSellerOrderSchema.parse(response.data?.brandOrder);
    },
  },
  getShippingCarriers: {
    queryKey: 'getShippingCarriers',
    schema: getShippingCarriersSchema,
    query: async () => {
      const response = await axiosAuth.get(`/order/seller/get-delivery-carriers`);
      return getShippingCarriersSchema.parse(response.data);
    },
  },
  updateShipping: {
    schema: z.string(),
    mutation: async ({
      brandOrderId,
      carrierId,
      trackingNumber,
    }: {
      brandOrderId: number;
      carrierId: number;
      trackingNumber: string;
    }) => {
      await axiosAuth.post('/order/seller/add-delivery-details', {
        brandOrderId,
        deliveryCompanyId: carrierId,
        ...(trackingNumber.length > 0 && { deliveryTrackingId: trackingNumber }),
      });
    },
  },
  amendOrder: {
    schema: z.string(),
    mutation: async ({
      brandOrderId,
      cart,
    }: {
      brandOrderId: number;
      cart: { sku: string; quantity: number }[];
    }) => {
      await axiosAuth.post('/order/seller/amend-order', {
        brandOrderId,
        cart,
      });
    },
  },
  exportCSV: {
    mutationKey: 'export-CSV-orders-sellers',
    schema: z.string(),
    mutation: async ({
      brandId,
      type,
    }: {
      brandId: number;
      type: 'action_required' | 'processing' | 'all' | 'proposals';
    }) => {
      const response = await axiosAuth.get('/order/seller/export-csv', {
        params: {
          brandId,
          ...(type !== 'all' && { type }),
        },
      });
      return z.string().parse(response.data);
    },
  },
  getOrderInvoice: {
    mutationKey: 'getOrderInvoice',
    schema: getOrderInvoiceSchema,
    mutation: async ({ brandOrderId }: { brandOrderId: number }) => {
      const response = await axiosAuth.get('/invoice/seller/bill', {
        params: {
          brandOrderId,
        },
        responseType: 'blob',
      });

      return getOrderInvoiceSchema.parse(response.data);
    },
  },
  getOrderPurchaseInvoice: {
    mutationKey: 'getOrderPurchaseInvoice',
    schema: getOrderPurchaseInvoiceSchema,
    mutation: async ({ brandOrderId }: { brandOrderId: number }) => {
      const response = await axiosAuth.get('/invoice/seller/purchaseOrder', {
        params: {
          brandOrderId,
        },
        responseType: 'blob',
      });

      return getOrderPurchaseInvoiceSchema.parse(response.data);
    },
  },

  getSellerCustomerOrders: {
    queryKey: 'getSellerCustomerOrders',
    schema: getSellerOrdersSchema,
    query: async ({
      retailerId,
      brandId,
      type,
      skip,
      take,
      searchTerm,
    }: {
      retailerId: number;
      brandId: number;
      type: string | null;
      skip: number;
      take: number;
      searchTerm: string;
    }) => {
      const response = await axiosAuth.get(
        `/order/seller/get-orders?brandId=${brandId}&retailerId=${retailerId}&take=${take}&skip=${skip}`,
        {
          params: {
            type,
            ...(searchTerm && { searchTerm }),
          },
        }
      );
      return getSellerOrdersSchema.parse(response.data);
    },
  },
};
