import { z } from 'zod';
import { axiosAuth } from '@libs/axios';

const OrderFormSharedAttributes = z.object({
  parentUniqueRowId: z.string().nullable(),
  uniqueRowId: z.string(),
});

const OrderFormProductRow = OrderFormSharedAttributes.extend({
  type: z.literal('productRow'),
  parentUniqueRowId: z.string().nullable(),
  uniqueRowId: z.string(),
  itemId: z.number(),
  hierarchy: z.array(z.string()).length(1),
  brandId: z.number(),
  brandName: z.string(),
  productId: z.string(),
  productName: z.string(),
  heroCategory: z.string().nullable(),
  category: z.string().nullable(),
  productType: z.string().nullable(),
  imageURL: z.string().nullable(),
});

const OrderFormPrimaryVariantRow = OrderFormSharedAttributes.extend({
  type: z.literal('primaryVariantRow'),
  parentUniqueRowId: z.string(),
  uniqueRowId: z.string(),
  itemVariantId: z.number(),
  hierarchy: z.array(z.string()).length(2),
  brandId: z.number(),
  productId: z.string(),
  variantId: z.string(),
  variantValue: z.string().nullable(),
  variantImageURL: z.string().nullable(),
  colourPill: z
    .object({
      name: z.string(),
      hex: z.string().nullable(),
    })
    .nullable(),
  hasMultipleRrp: z.boolean(),
  hasMultipleWhs: z.boolean(),
  displayRrp: z.number(),
  displayWhs: z.number(),
  whsMap: z.record(z.string(), z.number()),
  appliedCollectionId: z.number().nullable(),
  collections: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      additionalDiscount: z.number(),
      hasOrderWindow: z.boolean(),
      orderStartDate: z.string().nullable(),
      orderEndDate: z.string().nullable(),
      deliveryStartDate: z.string().nullable(),
      deliveryEndDate: z.string().nullable(),
      type: z.enum(['scheduled', 'in-season', 'indent']),
    })
  ),
  requestedFor: z.string().nullable(),
  indentOnly: z.boolean(),
  error: z
    .object({
      name: z.string(),
      message: z.string(),
    })
    .optional(),
});

export const StoresSchema = z.array(
  z.object({ id: z.number(), name: z.string(), orderFormStoreId: z.number() })
);
export type StoresType = z.infer<typeof StoresSchema>;

const OrderFormSecondaryVariantRow = OrderFormSharedAttributes.extend({
  type: z.literal('secondaryVariantRow'),
  parentUniqueRowId: z.string(),
  uniqueRowId: z.string(),
  itemVariantId: z.number(),
  itemSKUDetailId: z.number().nullable(),
  hierarchy: z.array(z.string()).length(3),
  brandId: z.number(),
  productId: z.string(),
  variantId: z.string(),
  sku: z.string(),
  secondaryVariantValue: z.string(),
  stock: z.number().nullable(),
  quantity: z.number(),
  rrp: z.number().default(0),
  whsMap: z.record(z.string(), z.number()),
  storeToQuantityMap: z.record(z.number()),
});

export const orderFormRowsSchema = z.array(
  z.discriminatedUnion('type', [
    OrderFormProductRow,
    OrderFormPrimaryVariantRow,
    OrderFormSecondaryVariantRow,
  ])
);

export type OrderFormRowsType = z.infer<typeof orderFormRowsSchema>;

export const getOrderFormSchema = z.object({
  orderFormId: z.number(),
  name: z.string(),
  orderFormRows: orderFormRowsSchema,
  brandDetailsLookup: z.record(
    z.object({
      id: z.number(),
      name: z.string(),
      slug: z.string(),
      logoURL: z.string().nullable(),
      buyerTerms: z.object({
        type: z.enum(['global', 'persona']),
        discount: z.number(),
        additionalDiscount: z.number(),
        moq: z.number(),
        priceListId: z.number().nullable(),
      }),
    })
  ),
  stores: StoresSchema,
});

export const getOrderFormsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  })
);

const mutationResponseSchema = z.object({});

const createOrderFormRequestSchema = z.object({
  retailerId: z.number(),
  name: z.string(),
  addProductIds: z.array(z.string()),
  addVariants: z.array(z.object({ productId: z.string(), variantId: z.string() })),
});

const updateOrderFormRequestSchema = z.object({
  orderFormId: z.number(),
  addProductIds: z.array(z.string()).optional(),
  addVariants: z.array(z.object({ productId: z.string(), variantId: z.string() })).optional(),
  addItemSKUDetails: z
    .array(
      z.object({
        sku: z.string(),
        productId: z.string(),
        variantId: z.string(),
        itemVariantId: z.number(),
        quantity: z.number(),
        orderFormStoreId: z.number(),
      })
    )
    .optional(),
  updateItemVariants: z
    .array(
      z.object({
        itemVariantId: z.number(),
        appliedCollectionId: z.number().nullable().optional(),
        requestedFor: z.string().nullable().optional(),
      })
    )
    .optional(),
  updateItemSKUDetails: z
    .array(
      z.object({
        itemSKUDetailId: z.number(),
        quantity: z.number(),
      })
    )
    .optional(),
  removeItemIds: z.array(z.number()).optional(),
  removeItemVariantIds: z.array(z.number()).optional(),
});

const checkoutOrderFormRequestSchema = z.object({
  orderFormId: z.number(),
  stores: z.array(z.object({ storeId: z.number(), notes: z.string() })),
});

const checkoutOrderFormResponseSchema = z.object({
  draftOrderId: z.number(),
});

const updateOrderFormStoresRequestSchema = z.object({
  orderFormId: z.number(),
  addStoreIds: z.array(z.number()),
  removeStoreIds: z.array(z.number()),
});

const orderForm = {
  getOrderForms: {
    queryKey: 'getOrderFormsQueryKey',
    schema: getOrderFormsSchema,
    query: async (retailerId: number) => {
      const response = await axiosAuth.get(`/order-form/get-order-forms?retailerId=${retailerId}`);
      return getOrderFormsSchema.parse(response.data?.result?.orderForms);
    },
  },
  getOrderForm: {
    queryKey: 'getOrderForm',
    schema: getOrderFormSchema,
    query: async (id: number) => {
      const response = await axiosAuth.get(`/order-form/get-order-form?orderFormId=${id}`);
      return getOrderFormSchema.parse(response.data?.result);
    },
  },
  createOrderForm: {
    mutationKey: 'createOrderForm',
    requestSchema: createOrderFormRequestSchema,
    responseSchema: mutationResponseSchema,
    mutation: async (payload: z.infer<typeof createOrderFormRequestSchema>) => {
      const response = await axiosAuth.post(`/order-form/create-order-form`, payload);
      return response.data;
    },
  },
  updateOrderForm: {
    mutationKey: 'updateOrderForm',
    requestSchema: updateOrderFormRequestSchema,
    responseSchema: mutationResponseSchema,
    mutation: async (payload: z.infer<typeof updateOrderFormRequestSchema>) => {
      const response = await axiosAuth.post(`/order-form/update-order-form`, {
        orderFormId: payload.orderFormId,
        addProductIds: payload.addProductIds || [],
        addVariants: payload.addVariants || [],
        addItemSKUDetails: payload.addItemSKUDetails || [],
        updateItemVariants: payload.updateItemVariants || [],
        updateItemSKUDetails: payload.updateItemSKUDetails || [],
        removeItemIds: payload.removeItemIds || [],
        removeItemVariantIds: payload.removeItemVariantIds || [],
      });
      return response.data;
    },
  },
  checkoutOrderForm: {
    mutationKey: 'checkoutOrderForm',
    requestSchema: checkoutOrderFormRequestSchema,
    responseSchema: checkoutOrderFormResponseSchema,
    mutation: async (payload: z.infer<typeof checkoutOrderFormRequestSchema>) => {
      const response = await axiosAuth.post(`/order-form/submit-order-form`, {
        orderFormId: payload.orderFormId,
        stores: payload.stores,
      });
      return response.data?.result;
    },
  },
  updateOrderFormStores: {
    mutationKey: 'updateOrderFormStores',
    requestSchema: updateOrderFormStoresRequestSchema,
    mutation: async (payload: z.infer<typeof updateOrderFormStoresRequestSchema>) => {
      const response = await axiosAuth.post(`/order-form/update-order-form-stores`, {
        orderFormId: payload.orderFormId,
        addStoreIds: payload.addStoreIds,
        removeStoreIds: payload.removeStoreIds,
      });
      return response.data?.result;
    },
  },
};

export type orderForm = typeof orderForm;

export default orderForm;
